import { template as template_6be51ba23d80401680287d4d5a4013a4 } from "@ember/template-compiler";
const FKText = template_6be51ba23d80401680287d4d5a4013a4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
