import { template as template_196968d88fce4cd1b73c889293ff2733 } from "@ember/template-compiler";
const FKLabel = template_196968d88fce4cd1b73c889293ff2733(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
