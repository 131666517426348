import { template as template_884e09faf10142caa4b887843b85d6b1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_884e09faf10142caa4b887843b85d6b1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
